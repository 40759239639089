import * as React from "react"
import LmtLogo from "../components/images/lmtLogo"



// markup
const NotFoundPage = () => {
  return (
    <main className="h-screen flex items-center ali">
      <div className="flex gap-10 container flex-col items-center text-center mx-auto p-10">
        <div className="w-50">
          <LmtLogo />

        </div>
        <div>
          <h1>404</h1>

          <h3>We could not find this page because it does not exist.</h3>
          <button><a href="/index.html">Go home</a></button>
        </div>

      </div>

    </main>
  )
}

export default NotFoundPage
